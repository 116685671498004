customElements.define(
    "word-rotate",
    class extends HTMLElement {
        constructor() {
            super();

            this.playAnimation = !window.matchMedia("(prefers-reduced-motion)")
                .matches;
            this.words = this.getAttribute("words").split(",");
            this.index = 0;

            this.word = this.words[this.index];

            if (this.playAnimation) {
                window.setInterval(() => {
                    this.index++;
                    if (this.index == this.words.length) {
                        this.index = 0;
                    }
                    this.word = this.words[this.index];
                }, 3500);
            }
        }

        connectedCallback() {
            let menu = document.querySelector("m-menu");
            if (menu) {
                menu.addEventListener("toggle", (event) => {
                    this.playAnimation = !menu.hasAttribute("open");
                    this.querySelector("span").classList.remove("fadeInAndOut");
                });
            }
        }

        set word(_word) {
            let span = document.createElement("span");
            span.innerText = _word;
            if (this.playAnimation) {
                span.classList.add("fadeInAndOut");
            }
            this.innerHTML = "";
            this.appendChild(span);
        }
    },
);
